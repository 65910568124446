import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)
import { getCookie, setCookie } from '/lib/cookie'

$(document).ready(function () {
  function openModal(target) {
    $.magnificPopup.open({
      items: {
        src: target,
        type: 'inline',
      },
      callbacks: {
        close: function () {
          setCookie('announcement-popup', 'true', 1);
        }
      },
      closeBtnInside: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
    });
  }
  if ( !getCookie('announcement-popup') && $('#announcement-popup').length ) {
    setTimeout(() => {
      openModal('#announcement-popup');
    }, "2000");
  }
})
