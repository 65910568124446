import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(document).ready(function(){
  if( ! getCookie( 'announcement-banner' ) ){
    const $notice = $('.js-announcement-banner')
    $notice.slideDown()
    $(".js-announcement-banner .js-close").on("click", function () {
      $notice.slideUp(300)
      setCookie('announcement-banner', 'true', 1);
    });
  }
})
